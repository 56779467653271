




























































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import Banner from "@/components/Banner.vue";
import SpeakerBlock from "@/components/SpeakerBlock.vue";
import {CustomField, CustomValidatedField, Field, Survey} from "@/models/Survey";
import InputLine from "@/components/InputLine.vue";
import {
  CustomFieldWithValueString,
  FieldWithValueBoolean,
  FieldWithValueString,
  Salutation,
  UserData
} from "@/models/UserData";
import {Country, countryCodes, countryCodes as countries} from "@/models/CountryCodes";
import {QuestionType, SurveyQuestion} from "@/models/Question";
import {Validations} from "vuelidate-property-decorators";
import {maxLength, requiredIf, sameAs} from "vuelidate/lib/validators";
import {phoneValidation, validateCity, validateEmail, validateMultipleText, validateVvid} from "@/utils/validators";
import {formatQuestionCount, scrollToElementById, setPage} from "@/utils/utils";
import {MultipleTextAnswer, SurveyQuestionAnswer} from "@/models/Response";
import SurveyService from "@/service/SurveyService";
import {AxiosError} from "axios";
import PhoneValidationService from "@/service/PhoneValidationService";
import {sanitizeCustomCheckbox} from "@/utils/sanitizeHtml";


@Component({
  methods: {sanitizeCustomCheckbox},
  components: {
    Logo,
    Banner,
    SpeakerBlock,
    InputLine
  }
})


export default class SurveyFormPage extends Vue {

  private questionTypeEnum = QuestionType;
  private formatQuestionCount = formatQuestionCount;

  showValidation = false;

  countryCodes = countries;
  currentCountryCode = 'DE';
  currentPhone = "";

  fieldsString: Array<FieldWithValueString> = new Array<FieldWithValueString>();
  fieldsBoolean: Array<FieldWithValueBoolean> = new Array<FieldWithValueBoolean>();
  customFieldsString: Array<CustomFieldWithValueString> = new Array<CustomFieldWithValueString>();

  surveyQuestionAnswers: Array<SurveyQuestionAnswer> = new Array<SurveyQuestionAnswer>();

  mounted(): void {
    this.initFields();
    this.initPhone();
  }

  @Watch('userData')
  onUserDataChanged(): void {
    this.initFields();
    this.initPhone();
  }

  initPhone(): void {
    if (!this.form) return;
    this.currentCountryCode = this.form.branding?.countryISO || "DE";

    if (!this.userData.phone) {
      return;
    }
    PhoneValidationService.validatePhoneNumber(this.userData.phone, "DE")
        .then(validatedNumber => {
          if(validatedNumber.regionCode){
            this.currentCountryCode = validatedNumber.regionCode || this.currentCountryCode;
          }
          if (this.currentCountry) {
            if(validatedNumber.international){
              this.currentPhone = validatedNumber.international.slice(this.currentCountry.dialCode.length).trim();
            } else {
              this.currentPhone = '';
            }
          }
        });
  }

  initFields(): void {
    if (!this.form) return;
    this.fieldsString.splice(0, this.fieldsString.length);
    this.getFields(this.form.participantDataToSave.customFields).forEach(field => this.fieldsString.push(field));

    this.fieldsBoolean.splice(0, this.fieldsBoolean.length);
    this.getFieldsBoolean(this.form.participantDataToSave.customCheckboxes).forEach(field => this.fieldsBoolean.push(field));

    this.customFieldsString.splice(0, this.customFieldsString.length);
    this.getCustomFields(SurveyService.sortFields(this.form.customFields || [])).forEach(field => this.customFieldsString.push(field));

    if (this.userData.fieldValues) {
      this.userData.fieldValues.forEach(value => {
        const stringField = this.fieldsString.find(field => field.field.id === value.id);
        if (stringField) stringField.value = value.value;
        const booleanField = this.fieldsBoolean.find(field => field.field.id === value.id);
        if (booleanField) booleanField.value = value.value === 'true';
      })
    }

    if (this.userData.customFieldValues) {
      this.userData.customFieldValues.forEach(value => {
        const customField = this.getCustomField(value.fieldId);
        if (customField) customField.value = value.value;
      })
    }

    this.surveyQuestionAnswers.splice(0, this.surveyQuestionAnswers.length);
    this.getQuestions(this.form.questions).forEach(question => this.surveyQuestionAnswers.push(question))
  }

  get currentCountry(): Country {
    return this.countryCodes.find(c => c.isoCode == this.currentCountryCode) || countryCodes[0]
  }

  submitSurvey(): void {
    if (!this.form) return;

    this.showValidation = true;
    if (this.$v.$invalid) {
      scrollToElementById('validation')
    } else {
      setPage(this, 'loading', true);
      this.fieldsString.forEach(field => {
        const existingField = this.userData.fieldValues.find(f => f.id === field.field.id);
        if (existingField) existingField.value = field.value;
        else this.userData.fieldValues.push({id: field.field.id, value: field.value});
      });

      this.fieldsBoolean.forEach(field => {
        const existingField = this.userData.booleanValues.find(f => f.id === field.field.id);
        if (existingField) existingField.value = field.value ? 'true' : 'false';
        else this.userData.booleanValues.push({id: field.field.id, value: field.value ? 'true' : 'false'});
      });

      this.userData.customFieldValues = [];
      this.customFieldsString.forEach(field => this.userData.customFieldValues.push({
        fieldId: field.field.id,
        value: field.value
      }));

      this.surveyQuestionAnswers.forEach(answer => this.userData.answers.push(answer));
      if (this.form.saveParticipantData) this.userData.phone = this.$store.getters.phone;

      SurveyService.sendSurveyResponse(this.form, this.userData)
          .then(result => {
            this.userData.result = result;
            setPage(this, 'submitted', true);
          })
          .catch((e: AxiosError) => {
            if (e.response?.status === 400) {
              if (e.response.data.message.includes("Mail or phone")) {
                setPage(this, "banned", true);
              } else {
                setPage(this, "already_registered", true);
              }
            } else {
              setPage(this, "error", true);
            }
          });
    }
  }

  @Validations()
  validations(): any {
    let pData = this.form.participantDataToSave;

    return {
      userData: {
        salutation: {required: requiredIf(() => pData.salutation.required)},
        firstname: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.name.required)
        },
        lastname: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.name.required)
        },
        addressStreet: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.address.required)
        },
        addressCity: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.address.required),
          validateCity: validateCity
        },
        company: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.company.required)
        },
        email: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.email.required),
          email: validateEmail
        },
        vvId: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.vvId.required),
          validateVvid: validateVvid,
          notExample: (value: string) => !(this.$t('form.vvIdHint') as string).includes(value) || value === ''
        },
        partnerId: {
          maxLength: maxLength(255),
          required: requiredIf(() => pData.partnerId.required)
        },
        comment: {required: requiredIf(() => pData.comment.required)}
      },
      currentPhone: {
        phoneFormat: pData.phone.show && this.currentPhone !== ''
            ? phoneValidation(this, this.currentCountry.isoCode)
            : true,
        required: requiredIf(() => pData?.phone.required)
      },
      fieldsString: {
        $each: {
          value: {
            required: requiredIf((field: FieldWithValueString) => field.field.required)
          }
        }
      },
      fieldsBoolean: {
        $each: {
          value: {
            sameAs: sameAs((field: FieldWithValueBoolean) => field.field.required ? true : field.value) // Value must be true
          }
        }
      },
      customFieldsString: {
        $each: {
          value: {
            required: requiredIf((field: CustomFieldWithValueString) => field.field.required),
            regex: (value: string, vm: CustomFieldWithValueString) => {
              if (value !== "" && (vm.field.regex && vm.field.regex !== "")) {
                const regexPattern = new RegExp("^" + vm.field.regex + "$", vm.field.ignoreCase ? "i" : "");
                return regexPattern.test(value);
              }
              return true;
            },
            length: (value: string, vm: CustomFieldWithValueString) => {
              if (vm.field.maxLength != undefined && !(vm.field.maxLength <= 0)) {
                return value ? !(value.length > vm.field.maxLength) : true;
              }
              return true;
            }
          }
        }
      },
      surveyQuestionAnswers: {
        $each: {
          answer: {
            validateMultipleText: validateMultipleText,
            required: requiredIf((answer: SurveyQuestionAnswer) => answer.question.required)
          }
        }
      }
    };
  }

  label(name: string, required: boolean): string {
    return this.$t(`form.${name}`) + (required ? '*' : '')
  }

  get form(): Survey {
    return this.$store.getters.form;
  }

  get salutations(): Array<string> {
    return [Salutation.MALE, Salutation.FEMALE, Salutation.DIVERS]
  }

  get userData(): UserData {
    return this.$store.getters.userData;
  }

  get footerEnabled(): boolean {
    return this.$store.getters.footerEnabled;
  }

  get footer(): string {
    return this.$store.getters.footer;
  }

  get privacyLabel(): string {
    return this.$store.getters.privacy;
  }

  getFields(fields: Array<CustomField> | undefined): Array<FieldWithValueString> {
    if (!fields) return [];
    return fields.map((field, index) => {
      let f: Field = {
        id: field.id,
        title: field.caption,
        type: 'TEXT_LINE',
        required: field.required,
        idx: index
      };
      return {field: f, value: ""};
    });
  }

  getCustomFields(fields: Array<CustomValidatedField> | undefined): Array<CustomFieldWithValueString> {
    if (!fields) return [];
    return fields.map(field => {
      return {field, value: ""};
    });
  }

  getFieldsBoolean(fields: Array<CustomField> | undefined): Array<FieldWithValueBoolean> {
    if (!fields) return [];
    return fields.map((field, index) => {
      let f: Field = {
        id: field.id,
        title: field.caption,
        type: 'CHECKBOX',
        required: field.required,
        idx: index
      };
      return {field: f, value: false};
    });
  }

  getCustomField(id: number): CustomFieldWithValueString | undefined {
    return this.customFieldsString.find(field => field.field.id === id);
  }

  getQuestions(questions: Array<SurveyQuestion> | undefined): Array<SurveyQuestionAnswer> {
    if (!questions) return [];
    return questions.map(question => {
      let q: SurveyQuestionAnswer = {
        question: question,
        answer: []
      };
      let tmp: Array<MultipleTextAnswer> = [];
      switch (question.type) {
        case QuestionType.MULTIPLE_TEXT:
          question.multipleTextQuestionOptions?.forEach(o => {
            tmp.push({
              id: o.id,
              answer: ""
            })
          });
          q.answer = tmp;
          break;
        case QuestionType.TEXT:
          q.answer = "";
          break;
      }
      return q;
    })
  }

  prepareCheckboxHtml(html: string): string {
    const sanitized = sanitizeCustomCheckbox(html);

    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitized, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  }
}
